import * as React from 'react';
import moment from 'moment';
import {
  Collapse,
  Button,
  Col,
  DatePicker,
  Row,
  Icon,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Field, { FormItem } from '../../ui/Field';
import dateConfig from '../../configs/date';

const { Panel } = Collapse;

export interface InNoteList {
  id: string;
  date?: string | null;
  description: string;
}

interface InState {
  noteList: InNoteList[];
  loaded: boolean;
}


export default class NoteDate extends React.Component<any, InState> {
  constructor(props: any) {
    super(props);
    this.state = {
      noteList: [],
      loaded: false,
    };
  }


  static getDerivedStateFromProps (props: any, state: InState): InState {
    if (props.noteList && !state.loaded) {
      return {
        ...state,
        noteList: props.noteList.map((item: InNoteList) => ({
          ...item,
          id: item.id || (Number(Date.now()) - Math.floor(Math.random() * 1000) + 1).toString(),
        })),
        loaded: true
      };
    }

    return state;
  }

  static parseFormValues = (values: any = {}): Omit<InNoteList, 'id'>[] => {
    const data = Object
      .keys(values)
      .reduce((acc: Omit<InNoteList, 'id'>[], key) => {
        if (/^noteDate-/.test(key)) {
          const id = key.split('-')[1];
          const descKey = `noteDescription-${id}`;
          acc.push({
            date: moment(values[key]).toString() || '',
            description: values[descKey]?.toString() || '',
          });
          return acc;
        }
        return acc;
      }, []);

    return Object.values(data);
  };

  add = (): void => {
    const { noteList } = this.state;
    const currDate = moment().toString();

    this.setState({
      noteList: [
        ...noteList,
        {
          id: (Number(Date.now()) + Math.floor(Math.random() * 1000) + 1).toString(),
          date: currDate,
          description: '',
        },
      ],
    });
  };

  remove = (id: string): void => {
    const { noteList } = this.state;

    const newNoteList = noteList.filter((item) => item.id !== id);

    this.setState({
      noteList: newNoteList,
    });
    // this.setState((prevState) => {
    //   const newNoteList = prevState.noteList.filter((v: InNoteList, ind: number) => ind !== id);
    //   return { noteList: newNoteList };
    // }, () => {
    //   console.log('After setState:', this.state.noteList);
    // });
  };


  handleOnChange = (id: string, val: { toISOString: () => string }): void => {
    const { noteList } = this.state;
    this.setState({
      noteList: noteList.map((item) =>
        item.id === id ? { ...item, date: val?.toISOString() || null } : item
      ),
    });
  };

  handleOnChangeDesc = (id: string, val: { target: { value: string } }): void => {
    const { noteList } = this.state;
    this.setState({
      noteList: noteList.map((item) =>
        item.id === id ? { ...item, description: val.target.value } : item
      ),
    });
  };

  render(): JSX.Element {
    const { noteList, loaded } = this.state;
    const title = 'One to one notes';

    return (

      <Collapse
        bordered={ false }
        defaultActiveKey={ noteList.length === 0 ? '1' : '' }
        >
        <Panel header={ title } key="1" showArrow={ true }>

          <FormItem label={ null }>
            { loaded && Boolean(noteList && noteList.length) &&
              noteList.map((item: InNoteList) =>
              // const { isNew } = item;

                (<Row gutter={ 20 } key={ item.id }>
                  <Col md={ { span: 8 } }>

                    <Field
                      label='Date'
                      name={ `noteDate-${item.id}` }
                      placeholder="note Date"
                      defaultValue={ item && item.date  ?  moment(item.date) : moment() }
                      onChange={ (val: moment.Moment) => this.handleOnChange(item.id, val) }
                    >
                      <DatePicker
                        format={ dateConfig.formatHolidayDatePicker }
                        defaultValue={ item && item.date  ?  moment(item.date) : moment() }
                        defaultPickerValue={ item && item.date && item.date != null  ?  moment(item.date) : moment() }

                      />
                    </Field>
                  </Col>
                  <Col md={ { span: 14 } }>
                    { /* <div className="ant-col ant-form-item-label">
                      <label
                        htmlFor={ `noteDescription-${key}` }
                        className="ant-form-item-no-colon"
                        title="Note">
                        Note
                      </label>
                    </div> */ }
                    <Field
                      label="Note"
                      name={ `noteDescription-${item.id}` }
                      placeholder="Note"
                      defaultValue={ item.description ?? '' }
                      onChange={ (event: { target: { value: string } }) => this.handleOnChangeDesc(item.id, event) }
                    >
                      <TextArea rows={ 2 } />
                    </Field>
                  </Col>
                  <Col md={ { span: 2 } } className="mt-30">
                    <span
                      className={ `cursor ---${item.id}---` }
                      onClick={ () => this.remove(item.id) }
                    >
                      <Icon type="close-circle" />
                    </span>
                  </Col>
                </Row>)
              ) }
            <Row type="flex">
              <Button onClick={ this.add }><Icon type="plus" />Add</Button>
            </Row>
          </FormItem>

        </Panel>
      </Collapse>
    );
  }
}
